import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import Container from '@/components/Layout/Container'
import Gutenberg from '@/components/Gutenberg'
import Seo from '@/components/Seo'
import MainHeading from '@/components/MainHeading'
import { seotype } from '@/types.d'
interface DefaultData {
  data: {
    form: any
    page: {
      title: string
      content: string
      translations: any
      featuredImage: any
      uri: string
      seo: seotype
    }
    wp: any
    mainMenu: any
  }
  pageContext: {
    postlang: string
  }
}

const DefaultTemplate = ({ data, pageContext }: DefaultData) => {
  const { page } = data
  const { form } = data
  const { wp } = data
  const { mainMenu } = data

  const title = page.title
  const content = page.content
  const langData = { languages: wp.languages, currentLang: pageContext.postlang, translations: page.translations }

  return (
    <Layout featuredImage={page.featuredImage} nav={mainMenu} lang={langData} form={form}>
      {page.seo && (
        <Seo
          title={page?.seo?.title}
          description={page?.seo?.metaDesc}
          keywords={page?.seo?.metaKeyword}
          pathname={page?.uri}
          lang={pageContext.postlang}
          image={page?.featuredImage?.node?.localFile?.url}
          twitterTitle={page?.seo?.twitterTitle}
          twitterDescription={page?.seo?.twitterDescription}
          opengraphTitle={page?.seo?.opengraphTitle}
          opengraphDescription={page?.seo?.opengraphDescription}
          opengraphImage={page?.seo?.opengraphImage?.localFile?.url}
          twitterImage={page?.seo?.twitterImage?.localFile?.url}
        />
      )}
      {title && (
        <Container>
          <MainHeading>{title}</MainHeading>
        </Container>
      )}
      <Container>
        <Gutenberg>{content}</Gutenberg>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageById($id: String, $menu: [WpMenuLocationEnum], $translang: String, $formNum: Int) {
    # selecting the current page by id
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      uri
      ...seo
      ...featuredHeroImage
      ...langdata
    }
    mainMenu: wpMenu(locations: { in: $menu }) {
      ...navigation
    }
    wp {
      languages {
        name
        code
        slug
      }
    }
    locales: allLocale(filter: { language: { eq: $translang } }) {
      ...stringTranslations
    }
    form: wpGravityFormsForm(formId: { eq: $formNum }) {
      ...GravityFormFields
    }
  }
`

DefaultTemplate.defaultProps = {
  title: 'Default template title',
  content: 'Default template content',
}

export default DefaultTemplate
